<template>
    <div class="tab-panel">
        <ResidenceBreadcrumb :path="path" />
        <ClassicBreadCrumb :title="name" v-show="false" v-if="name" />
        <!-- ACTIONS -->
        <div class="actions">
            <!-- create -->
            <basic-button
                class="action create"
                color="orange"
                @click="createAction()"
                v-if="checkRight('G_RESIDENCES')"
            >
                <i class="fas fa-plus"></i>
                <span class="mobile">
                    {{ $t("entityDetails.actions.create") }}
                </span>
            </basic-button>
        </div>

        <!-- ACCESSES -->
        <div>
            <div class="middle-block">
                <h1 class="panel-title">
                    {{ $t("entityDetails.internalAccesses") }}
                </h1>
                <DisplayModeAccessCard />
            </div>

            <!-- Display List Columns -->
            <div
                :class="'accesses' + '-' + displayMode"
                v-if="accesses.length > 0"
            >
                <div
                    class="accesses-list-grid-title"
                    v-if="displayMode == 'list'"
                >
                    <div></div>
                    <div>
                        {{ $t("residencesList.arrayColumns.name") }}
                    </div>
                    <div>
                        {{ $t("centralUnitsList.arrayColumns.option") }}
                    </div>
                </div>
                <access-card
                    v-for="item in accesses"
                    :key="item.id"
                    :access="item"
                    :entityParent="entityParent"
                    :idParent="idParent"
                    :display="displayMode"
                >
                </access-card>
            </div>
            <div class="accesses" v-else>
                <access-card
                    :isCreator="true"
                    :entityParent="entityParent"
                    :idParent="idParent"
                    :display="displayMode"
                >
                </access-card>
            </div>
            <div
                class="accesses-show-button"
                v-if="accesses.length < countAccess"
            >
                <button @click="showMore">
                    <span>
                        {{ $t("button.seeAll") }}
                        <i class="fas fa-chevron-circle-down"></i>
                    </span>
                </button>
            </div>
        </div>
        <!-- STAIRWELLS -->
        <div class="stairwells" v-if="stairwells.length > 0">
            <stairwell-card
                v-for="item in stairwells"
                :key="item.id"
                :stairwell="item"
                :entityParent="entityParent"
                :entityParentId="idParent"
            ></stairwell-card>
        </div>
        <div class="stairwells" v-else>
            <stairwell-card
                :isCreator="true"
                :entityParent="entityParent"
                :entityParentId="idParent"
            ></stairwell-card>
        </div>
    </div>
</template>

<script>
import AccessCard from "@/components/entities/AccessCard.vue"
import StairwellCard from "@/components/entities/StairwellCard.vue"
import BasicButton from "@/components/basic/BasicButton.vue"
import DisplayModeAccessCard from "@/components/DisplayModeAccessCard.vue"
import ResidenceBreadcrumb from "@/components/entities/ResidenceBreadcrumb.vue"
import v1mixin from "@/mixins/v1.js"
import ClassicBreadCrumb from "@/components/entities/ClassicBreadCrumb.vue"
import { mapState } from "vuex"
import Infos from "./Infos.vue"

export default {
    name: "BuildingHome",
    components: {
        BasicButton,
        AccessCard,
        StairwellCard,
        DisplayModeAccessCard,
        ResidenceBreadcrumb,
        ClassicBreadCrumb,
    },
    methods: {
        showMore() {
            this.$emit("showMore")
        },
        createAction() {
            this.$emit("createAction")
        },
    },
    mixins: [v1mixin],
    props: {
        activeTab: {
            type: String,
            default: "details",
        },
        path: {
            type: Object,
            default: () => {},
        },
        name: {
            type: String,
            default: null,
        },
        accesses: {
            type: Array,
            default: () => [],
        },
        entityParent: {
            type: String,
            default: "",
            required: true,
        },
        idParent: {
            type: String,
            default: "",
        },
        countAccess: {
            type: Number,
            default: 0,
        },
        stairwells: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        ...mapState({
            displayMode: (state) => state.account.displayAccessCard,
        }),
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_loader.scss";
@import "@/assets/scss/details.scss";
@import "@/assets/scss/variables/fontSize.scss";

@media all and (max-width: 1024px) {
    .mobile {
        display: none;
    }
}

@media all and (min-width: 1024px) {
    .no-mobile {
        display: none;
    }
}

.panels {
    width: 100%;
}

.middle-block {
    display: flex;
    align-items: center;
    margin-bottom: 2em;
    width: 100%;
    justify-content: space-between;

    h1 {
        margin: 0;
    }

    div {
        display: flex;

        gap: 1em;
        height: max-content;

        span {
            color: $grey-darker;
            border-radius: 4px;
            transition: all 0.3s ease-in-out;
            display: flex;
            align-items: center;
            padding: 0 1em;
            font-family: $font_avenir_heavy;
        }

        i {
            color: $grey-darker;
            cursor: pointer;
            padding: 8px;
            border-radius: 4px;
            transition: all 0.3s ease-in-out;

            &:hover {
                background-color: $grey-neutral;
                color: $orange-light;
                transition: all 0.3s ease-in-out;
            }
        }
    }
}

.accesses-card {
    display: grid !important;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
    gap: 2em;
    margin: 0;

    @media all and (max-width: 1024px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media all and (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr);
    }
}

.accesses-list {
    display: flex;
    flex-direction: column;

    .accesses-list-grid-title {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 10fr 1fr;
        align-items: center;
        border-bottom: 1px solid $grey-light;
        padding: 1em 0;

        div:nth-child(1) {
            min-width: 150px;
        }

        div:nth-child(3) {
            justify-items: end;
        }
    }

    div {
        border-top: 0px;
        border-left: 0px;
        border-right: 0px;
        border-radius: 0px;

        div {
            display: grid;
            grid-template-columns: 1fr, 1fr 1fr;
        }
    }
}
</style>
